<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="success" @click="addnew">新增征集</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="220" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">详情</el-button>
							<el-button @click="edit(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<tz_detail ref="tz_detail"></tz_detail>
	</div>
</template>

<script>
	import tz_detail from '../components/tz_detail.vue';
	export default {
		components: {
			tz_detail
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '大会发言征集标题',
						width: ''
					},
					{
						prop: 'wymeeting_name',
						name: '所属会议',
						width: ''
					},
					{
						prop: 'user_name',
						name: '发起人',
						width: '200'
					},
					{
						prop: 'addtime',
						name: '截止时间',
						width: '200'
					}
				],
				tableData: [],
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			showeidt(row) {
				this.$refs.tz_detail.info = row
				this.$refs.tz_detail.dialogVisible = true
			},
			edit(row) {
				this.$router.push('/system/dy_talk_list_edit?id=' + row.id)
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/invest_research_topics/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/dy_talk_list_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
			deleterow(row) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/invest_research_topics/del',
						params: {
							ids:row.id
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.gettable()
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import 'talk_list.scss';
</style>